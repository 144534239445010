import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { HomepageComponent } from './homepage/homepage.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { FarmerPrivacyComponent } from './farmer-privacy/farmer-privacy.component';
import { FarmerTermsComponent } from './farmer-terms/farmer-terms.component';

@NgModule({
  declarations: [
    AppComponent,
    PrivacyComponent,
    HomepageComponent,
    NavBarComponent,
    TermsOfUseComponent,
    DeleteAccountComponent,
    FarmerPrivacyComponent,
    FarmerTermsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
