import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"]
})
export class PrivacyComponent implements OnInit {
  public innerWidth: any;
  scrolling = false;
  mobile = false;
  mobileMenuClicked = false;

  ngOnInit() {
    // this.innerWidth = window.innerWidth;
    // if (this.innerWidth < 800) {
    //   this.mobile = true;
    // }
    // window.scroll(0, 0);
  }

}
