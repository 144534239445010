import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyComponent } from './privacy/privacy.component';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { FarmerPrivacyComponent } from './farmer-privacy/farmer-privacy.component';
import { FarmerTermsComponent } from './farmer-terms/farmer-terms.component';


const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'privacy', component: PrivacyComponent, pathMatch: 'full' },
  { path: 'terms-of-use', component: TermsOfUseComponent, pathMatch: 'full' },
  { path: 'delete-account', component: DeleteAccountComponent, pathMatch: 'full' },
  { path: 'farmer-privacy', component: FarmerPrivacyComponent, pathMatch: 'full' },
  { path: 'farmer-terms', component: FarmerTermsComponent, pathMatch: 'full' },
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
